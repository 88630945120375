/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const headerBar = css`
  color: var(--blue);
  text-align: center;
  font-weight: 700;
  font-size: 34.4921px;
  line-height: 32px;
  margin-top: 35px;
  font-family: var(--defaultHeaderFontBold);
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 26px;
    margin-top: 13px;
    text-align: left;
    margin-left: 15px;
    max-width: 400px;
  }
`;

const headerLink = css`
  text-decoration: none;
`;

export const FancyItalics = styled.span`
  font-family: var(--alternativeHeaderFontItalic);
  font-size: 43.9px;
  line-height: 37.63px;
  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export default function HeaderBar() {
  return (
    <Link to="/" css={headerLink}>
      <div css={headerBar}>
        A <FancyItalics>Treaty</FancyItalics> Guide for{" "}
        <FancyItalics>Torontonians</FancyItalics>
      </div>
    </Link>
  );
}
